<template>
	<div class="block-wrap">
		<div class="block">
			<div class="item">
				<b>Total:</b> <span>{{ prettyNr(entity.artworkCount, $imperial) }}</span>
			</div>
			<div class="item">
				<b>Promoted:</b>
				<span
					>{{ prettyNr(entity.promotedCount, $imperial) }}
					<span class="soft"
						>&nbsp; - &nbsp;({{ Math.round((entity.promotedCount / entity.artworkCount) * 100) }}%)</span
					></span
				>
			</div>
		</div>
		<div class="block">
			<div class="item">
				<b>Contributed:</b
				><span
					>{{ prettyNr(entity.contributedCount, $imperial) || '-' }}
					<span class="soft"
						>&nbsp; - &nbsp;({{ Math.round((entity.contributedCount / entity.artworkCount) * 100) }}%)</span
					></span
				>
			</div>
			<div class="item">
				<b>Recollected:</b
				><span
					>{{ prettyNr(entity.recollectedCount, $imperial) || '-' }}
					<span class="soft"
						>&nbsp; - &nbsp;({{ Math.round((entity.recollectedCount / entity.artworkCount) * 100) }}%)</span
					></span
				>
			</div>
		</div>
		<div class="block">
			<div class="item">
				<b>Regular:</b>
				<span
					>{{ prettyNr(entity.artworkRegCount, $imperial) }}
					<span class="soft"
						>&nbsp; - &nbsp;({{ Math.round((entity.artworkRegCount / entity.artworkCount) * 100) }}%)</span
					></span
				>
			</div>
			<div class="item">
				<b>Mobile:</b>
				<span
					>{{ prettyNr(entity.artworkMobCount, $imperial) }}
					<span class="soft"
						>&nbsp; - &nbsp;({{ Math.round((entity.artworkMobCount / entity.artworkCount) * 100) }}%)</span
					></span
				>
			</div>
		</div>
	</div>

	<div class="sep"></div>

	<div id="options">
		<FormDropdown id="list" :options="listTypeDdOptions" v-model="listType" />
		<FormDropdown id="list" :options="pageSizeDdOptions" v-model="pageSize" />
	</div>

	<ArtworkList
		:artworks="artworks"
		:pageCount="pageCount"
		:page="page"
		:listType="listType"
		:classDim="classDim"
	/>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

// Components
import FormDropdown from '@/components/FormDropdown'
import ArtworkList from '@/components/ArtworkList'

// Internal
import { pageSizeDdOptions } from '@/use/General'
import { prettyNr } from '@/use/StringMagic'

export default {
	name: 'AdminInspectEntityArtworks',
	components: {
		FormDropdown,
		ArtworkList,
	},
	props: {
		// From parent
		entity: {
			type: Object,
			required: true,
		},

		// From route
		page: {
			type: Number,
			default: 1,
		},
		entityType: {
			type: String,
			required: true,
		},
		id_username_namePath: {
			type: String,
			required: true,
		},
	},
	setup() {
		const apiStore = useApiStore()
		const generalApi = apiStore.loadApi('general')

		return { generalApi, prettyNr, pageSizeDdOptions: pageSizeDdOptions() }
	},
	data() {
		return {
			listType: this.$route.query.listType,
			pageSize: this.$route.query.pageSize,
			pageCount: 0,
			total: 0,
			dim: false,
			artworks: [],
			listTypeDdOptions: [
				{
					value: 'list',
					display: 'List',
					// Default
					selected: !this.$route.query.listType || this.$route.query.listType == 'list',
				},
				{
					value: 'imageList',
					display: 'Image list',
					selected: this.$route.query.listType == 'imageList',
				},
			],
		}
	},
	computed: {
		classDim() {
			return this.dim ? 'dim' : ''
		},
		page_pageSize() {
			return this.page + '_' + this.pageSize
		},
	},
	watch: {
		// Note: there's a more elegant way of doing this
		// see Admin › Index › Entity.vue › beforeRouteUpdate
		async page_pageSize(newValue, oldValue) {
			newValue = newValue.split('_')
			oldValue = oldValue.split('_')
			const oldPage = +oldValue[0]
			const oldPageSize = +oldValue[1]
			const newPage = +newValue[0]
			const newPageSize = +newValue[1]

			if (newPageSize != oldPageSize) {
				// Changing pages size --> jump back tp page 1
				this.$router.replace({
					name: this.$route.name,
					params: { ...this.$route.params, page: 1 },
					query: { ...this.$route.query, pageSize: this.pageSize },
				})
				// When pageSize changes, page will be reset to one,
				// which will trigger another route change, which will
				// take care of reloading artworks
				if (oldPage == 1) this.getArtworks()
			} else if (newPage != oldPage) {
				this.getArtworks()
			}
		},
		listType() {
			this.$router.replace({
				name: this.$route.name,
				params: this.$route.params,
				query: { ...this.$route.query, listType: this.listType },
			})
		},
	},
	beforeMount() {
		// It's impossible (?) to redirect from within the router, because we need
		// to pass entityType and id_username_namePath, while we don't have access to that there
		if (!this.page) {
			this.$route.page = 1
			this.$router.push(this.$route)
		}
	},
	created() {
		this.getArtworks()
	},
	methods: {
		async getArtworks() {
			this.dim = true
			const response = await this.generalApi.getFeed(this.entityType, this.id_username_namePath, this.page, {
				pageSize: this.pageSize,
			})
			// When you
			if (response.status != 200) return

			const { artworks, pageCount, total } = response.data
			this.artworks = artworks
			this.pageCount = pageCount
			this.total = total
			this.dim = false
			this.goToPage1()
		},

		// When there's pagination, we forward to page 1
		goToPage1() {
			if (!this.$route.params.page && this.total > this.pageSize) {
				this.$route.params.page = 1
				this.$router.replace(this.$route)
			}
		},
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity-info.scss"></style>

<style lang="scss" scoped>
#options {
	margin-bottom: 0.3rem;
}
#options .dropdown {
	display: inline-block;
}
</style>
