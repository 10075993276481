<template>
	<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :currentPage="page" />

	<ol v-if="listType == 'list'" id="list" :class="classDim">
		<li v-for="(artwork, i) in artworks" :key="i">
			&nbsp;&nbsp;&nbsp;&nbsp;<router-link
				:to="{
					name: 'Artwork',
					params: {
						category: artwork.category,
						namePath: artwork.artistNamePath,
						titlePath: artwork.titlePath,
					},
				}"
				>Page</router-link
			>
			-
			<router-link
				:to="{
					name: 'AdminInspectEntity',
					params: { entityType: 'artwork', id_username_namePath: artwork.id },
				}"
				><i>{{ artwork.title }}</i></router-link
			>{{ artwork.year ? ', ' + artwork.year : '' }} by
			<router-link
				:to="{
					name: 'AdminInspectEntity',
					params: {
						entityType: 'artist',
						id_username_namePath: artwork.artistNamePath,
					},
				}"
				>{{ artwork.artistName }}</router-link
			>
		</li>
	</ol>

	<div v-else-if="listType == 'imageList'" id="image-list">
		<div v-for="(artwork, i) in artworks" :key="i" class="item">
			<div class="info" :class="classDim">
				<b
					><router-link
						:to="{
							name: 'AdminInspectEntity',
							params: {
								entityType: 'artwork',
								id_username_namePath: artwork.id,
							},
						}"
						><i>{{ artwork.title }}</i></router-link
					></b
				><br />
				<router-link
					:to="{
						name: 'AdminInspectEntity',
						params: {
							entityType: 'artist',
							id_username_namePath: artwork.artistNamePath,
						},
					}"
					>{{ artwork.artistName }}</router-link
				><br />
				{{ artwork.year }}<br />
			</div>
			<router-link
				:to="{
					name: 'Artwork',
					params: {
						category: artwork.category,
						namePath: artwork.artistNamePath,
						titlePath: artwork.titlePath,
					},
				}"
				target="_blank"
			>
				<BaseImageArt :itm="artwork" imgSize="small" />
			</router-link>
		</div>
	</div>

	<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :currentPage="page" class="bottom" />
</template>

<script>
import BasePagination from '@/components/BasePagination'
import BaseImageArt from '@/components/BaseImageArt'

export default {
	name: 'ArtworkList',
	components: {
		BasePagination,
		BaseImageArt,
	},
	props: {
		artworks: {
			type: Array,
			required: true,
		},
		pageCount: {
			type: Number,
			required: true,
		},
		page: {
			type: [Number, String],
			default: 1,
		},
		listType: {
			type: String,
			default: 'list',
		},
		classDim: {
			type: String,
			default: '',
		},
	},
	methods: {
		// Images are hidden until src loads
		onImgLoad(e) {
			e.target.style.opacity = 1
		},
	},
}
</script>

<style scoped lang="scss">
/* Regular list */
#list {
	line-height: 0.24rem;
}

/* Image list */
#image-list {
	width: 5rem;
}
#image-list .item {
	height: 1.2rem;
	background: #fff;
	border: solid 0.01rem $black-10;
	display: flex;
	width: 100%;
}
#image-list .item:not(:last-child) {
	border-bottom: none;
}
#image-list a {
	flex: 0 0;
}
#image-list img {
	height: 1.1rem;
	margin: 0.05rem;
}
#image-list .info {
	// background: #eee;
	flex: 1 1;
	padding: 0.2rem;
}
#image-list .info span {
	display: inline-block;
	width: 0.6rem;
}

.pagination.bottom {
	margin-top: 0.3rem;
}
</style>
